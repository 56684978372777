/**
 *  Bootstrap pull 3.x-1.1.1 for Twitter Bootstrap v3.
 *  
 *  To have pull-right and pull-left to Bootstrap v3 for xs, sm, md, lg media.
 *  This will help having some complex behavior for contents.
 *  https://github.com/Natshah/bootstrap-pull
 *
 */
@media (max-width: 767px) {
  /** Pull left xs **/
  .pull-left-xs {
    float: left;
  }
  /** Pull right xs **/
  .pull-right-xs {
    float: right;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /** Pull left sm **/
  .pull-left-sm {
    float: left;
  }
  /** Pull right sm **/
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /** Pull left **/
  .pull-left-md {
    float: left;
  }
  /** Pull right **/
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 1200px) {
  /** Pull left **/
  .pull-left-lg {
    float: left;
  }
  /** Pull right **/
  .pull-right-lg {
    float: right;
  }
}
.text-left-not-xs,
.text-left-not-sm,
.text-left-not-md,
.text-left-not-lg {
  text-align: left;
}
.text-center-not-xs,
.text-center-not-sm,
.text-center-not-md,
.text-center-not-lg {
  text-align: center;
}
.text-right-not-xs,
.text-right-not-sm,
.text-right-not-md,
.text-right-not-lg {
  text-align: right;
}
.text-justify-not-xs,
.text-justify-not-sm,
.text-justify-not-md,
.text-justify-not-lg {
  text-align: justify;
}
@media (max-width: 767px) {
  .text-left-not-xs,
  .text-center-not-xs,
  .text-right-not-xs,
  .text-justify-not-xs {
    text-align: inherit;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-center-xs {
    text-align: center;
  }
  .text-right-xs {
    text-align: right;
  }
  .text-justify-xs {
    text-align: justify;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .text-left-not-sm,
  .text-center-not-sm,
  .text-right-not-sm,
  .text-justify-not-sm {
    text-align: inherit;
  }
  .text-left-sm {
    text-align: left;
  }
  .text-center-sm {
    text-align: center;
  }
  .text-right-sm {
    text-align: right;
  }
  .text-justify-sm {
    text-align: justify;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .text-left-not-md,
  .text-center-not-md,
  .text-right-not-md,
  .text-justify-not-md {
    text-align: inherit;
  }
  .text-left-md {
    text-align: left;
  }
  .text-center-md {
    text-align: center;
  }
  .text-right-md {
    text-align: right;
  }
  .text-justify-md {
    text-align: justify;
  }
}
@media (min-width: 1200px) {
  .text-left-not-lg,
  .text-center-not-lg,
  .text-right-not-lg,
  .text-justify-not-lg {
    text-align: inherit;
  }
  .text-left-lg {
    text-align: left;
  }
  .text-center-lg {
    text-align: center;
  }
  .text-right-lg {
    text-align: right;
  }
  .text-justify-lg {
    text-align: justify;
  }
}
body {
  font-family: 'Lato';
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: disc;
}
* {
  outline: none !important;
}
a {
  text-decoration: none !important;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
}
a * {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
}
a:hover figure {
  -webkit-filter: sepia(0.25);
  filter: sepia(0.25);
}
@media (min-width: 1200px) {
  .container {
    width: 941px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 941px;
  }
}
@media (max-width: 768px) {
  .container {
    width: auto !important;
  }
  .row .row {
    margin: 0 !important;
  }
}
form .form-control {
  border-radius: 0 !important;
  border: none;
  box-shadow: none;
  resize: none;
}
form .form-control:focus {
  border-color: none;
  box-shadow: none;
}
form .form-controlinput[type="email"],
form .form-controlinput[type="text"] {
  height: 39px;
}
form ::-webkit-input-placeholder {
  color: #58585A;
  font-family: 'Playfair Display';
  font-size: 12px;
}
form ::-moz-placeholder {
  color: #58585A;
  font-family: 'Playfair Display';
  font-size: 12px;
}
form :-ms-input-placeholder {
  color: #58585A;
  font-family: 'Playfair Display';
  font-size: 12px;
}
form :-moz-placeholder {
  color: #58585A;
  font-family: 'Playfair Display';
  font-size: 12px;
}
form input[type="submit"] {
  border: 0;
  padding: 0;
}
.button {
  font-family: 'Playfair Display';
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none !important;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.button:focus {
  outline: none;
}
.button > span {
  vertical-align: middle;
}
.button.white-color {
  border: 2px solid #FFFFFF;
}
.button.white-color.button--wapasha {
  color: #FFFFFF;
}
.button.white-color.button--wapasha:before {
  border: 2px solid #FFFFFF;
}
.button.white-color.button--wapasha:hover {
  background-color: #FFFFFF;
  color: #000000;
}
.button.red-color {
  border: 2px solid #F23D3D;
  background-color: #F23D3D;
}
.button.red-color.button--wapasha {
  color: #FFFFFF;
}
.button.red-color.button--wapasha:before {
  border: 2px solid #F23D3D;
}
.button.red-color.button--wapasha:hover {
  background-color: #FFFFFF;
  color: #F23D3D;
}
.button.button--wapasha {
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
.button.button--wapasha:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: inherit;
  opacity: 0;
  -webkit-transform: scale3d(0.6, 0.6, 1);
  transform: scale3d(0.6, 0.6, 1);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button.button--wapasha:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.header-section .title {
  font-family: 'Playfair Display';
  font-size: 44px;
  color: #A62E24;
  font-weight: 700;
  margin: 0 0 20px 0;
}
.header-section .subtitle {
  font-family: 'Lato';
  color: #3C414E;
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 37px 0;
  letter-spacing: 0.26em;
  text-transform: uppercase;
}
.header-section .divisor {
  position: relative;
  display: block;
  width: 160px;
  height: 2px;
  background-color: #98A440;
}
.header-section .divisor:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -4px;
  background-color: #98A440;
}
.header-section.white .title {
  color: #FFFFFF;
}
.header-section.white .divisor {
  background-color: #FFFFFF;
}
.header-section.white .divisor:before {
  background-color: #FFFFFF;
}
.header-section.grey .title {
  color: #58585A;
}
.header-section.grey .divisor {
  background-color: #98A440;
}
.header-section.grey .divisor:before {
  background-color: #98A440;
}
.parallax-window {
  min-height: 400px;
  background: transparent;
  background-position: center center;
  background-size: cover;
}
@media (min-width: 1100px) {
  .parallax-window {
    background-attachment: fixed;
  }
}
.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.select2-container--default .select2-selection--single {
  background-color: #f0f0f0;
  border: 1px solid #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 50%;
  right: 1px;
  width: 20px;
  margin-top: -13px;
}
.select2-dropdown {
  background-color: #f0f0f0;
  border: 1px solid #fff;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}
.header-main {
  float: left;
  width: 100%;
  position: relative;
  z-index: 500;
}
.header-main.home {
  height: 192px;
  background: url(../img/bg-header-nav.png) repeat top center;
  margin-bottom: -192px;
}
.header-main.internal {
  height: 115px;
  background-color: black;
  margin-bottom: 84px;
}
.header-main .logo-header {
  width: 78px;
  height: 46px;
  margin: 18px 0 0 46px;
  background: url(../img/logo.png) no-repeat center center;
}
@media (max-width: 1025px) {
  .header-main .logo-header {
    margin-left: 0;
  }
}
.header-main .logo-header.logo-home {
  background-image: url(../img/logo-home.png);
}
.header-main .arrow-down {
  display: inline-block;
  width: 7px;
  height: 4px;
  background: url(../img/arrow-down-nav.png) no-repeat center center;
}
.header-main .nav-main .nav-header {
  margin: 26px 0 0 0;
}
.header-main .nav-main .nav-header li {
  display: inline-block;
  margin-right: 15px;
}
@media (max-width: 1025px) {
  .header-main .nav-main .nav-header li {
    margin-right: 5px;
  }
}
.header-main .nav-main .nav-header li:last-child {
  margin: 0;
}
.header-main .nav-main .nav-header li a {
  font-family: 'Playfair Display';
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: 0.2em;
}
.header-main .nav-main .nav-header li a:hover {
  color: #98A440;
}
.header-main .nav-main .lang,
.header-main .nav-main .dropdown {
  margin: 29px 0 0 44px;
}
.header-main .nav-main .lang li,
.header-main .nav-main .dropdown li {
  display: inline-block;
}
.header-main .nav-main .lang li a,
.header-main .nav-main .dropdown li a {
  font-family: 'Playfair Display';
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: 0.2em;
}
.header-main .nav-main .lang .btn,
.header-main .nav-main .dropdown .btn {
  background-color: transparent;
  border: none;
  font-family: 'Playfair Display';
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: 0.2em;
}
.header-main .nav-main .lang .dropdown-menu,
.header-main .nav-main .dropdown .dropdown-menu {
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.header-main .nav-main .lang .dropdown-menu li a,
.header-main .nav-main .dropdown .dropdown-menu li a {
  padding-left: 13px;
}
.header-main .nav-main .lang .dropdown-menu li a:hover,
.header-main .nav-main .dropdown .dropdown-menu li a:hover {
  background-color: rgba(32, 30, 30, 0.8);
}
.header-main .nav-main .phone {
  font-family: 'Playfair Display';
  font-weight: 900;
  color: #FFFFFF;
  font-size: 13px;
  letter-spacing: 0.25em;
}
.header-main .nav-main .btn-reserving {
  font-family: 'Playfair Display';
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: 0.2em;
  padding: 8px 11px;
  margin: 17px 0 0 0;
}
.header-main .head-language {
  width: 100%;
  background-color: #98A440;
  color: white;
  float: left;
  font-family: 'Playfair Display';
  font-size: 12px;
  padding: 8px 15px 0 15px;
}
.header-main .head-language ul {
  margin-right: 40px;
  text-transform: uppercase;
}
.header-main .head-language a {
  color: white;
  font-size: 13px;
}
.header-main .head-language a.current-lang {
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 1280px) and (min-width: 769px) {
  .header-main.internal {
    height: 120px;
  }
  .header-main .nav-main .lang {
    margin-top: 18px;
  }
  .header-main .nav-main .button {
    float: right !important;
    width: 90%;
    font-size: 10px;
  }
  .header-main .dropdown {
    margin: 15px 0px 0 10px !important;
  }
}
@media (max-width: 768px) {
  #nav-icon1 {
    position: absolute;
    top: 24px;
    right: 18px;
    width: 40px;
    height: 35px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 999999;
  }
  #nav-icon1 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 0;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #nav-icon1 span:nth-child(1) {
    top: 0px;
  }
  #nav-icon1 span:nth-child(2) {
    top: 13px;
  }
  #nav-icon1 span:nth-child(3) {
    top: 26px;
  }
  #nav-icon1.open span:nth-child(1) {
    top: 13px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  #nav-icon1.open span:nth-child(3) {
    top: 13px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .header-main .logo-header {
    margin-left: 18px !important;
  }
  .nav-main {
    overflow: hidden;
    position: absolute;
    top: 80px;
    width: 0;
    right: 0;
    z-index: 999999;
    margin: 0 !important;
    padding: 0;
    background: #000000;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
  }
  .nav-main.open {
    width: 100%;
  }
  .nav-main ul {
    margin: 0 !important;
    position: relative;
    width: 100%;
  }
  .nav-main ul li {
    width: 100%;
    display: block;
    border-bottom: 1px solid #FFFFFF;
    padding: 15px;
    margin-right: 0 !important;
  }
  .nav-main .button {
    display: block;
    clear: both;
    width: 50%;
    text-align: center;
    float: none !important;
    margin: 15px auto !important;
  }
  .header-section .title {
    font-size: 26px;
  }
}
aside {
  padding: 90px 0 0 0;
}
aside form {
  margin-bottom: 32px;
}
aside form .form-control {
  border: 1px solid #D6D6D6;
}
aside form ::-webkit-input-placeholder {
  font-family: 'Lato';
  color: #3C414E;
  font-size: 16px;
}
aside form ::-moz-placeholder {
  font-family: 'Lato';
  color: #3C414E;
  font-size: 16px;
}
aside form :-ms-input-placeholder {
  font-family: 'Lato';
  color: #3C414E;
  font-size: 16px;
}
aside form :-moz-placeholder {
  font-family: 'Lato';
  color: #3C414E;
  font-size: 16px;
}
aside .sidebar-title {
  font-size: 18px;
  color: #A62E24;
  text-transform: uppercase;
  letter-spacing: 0.24em;
  margin-bottom: 14px;
}
aside .sidebar-recent-posts {
  margin-bottom: 46px;
}
aside .sidebar-recent-posts ul li {
  font-size: 16px;
  color: #98A440;
  margin-bottom: 10px;
}
aside .sidebar-recent-posts ul li a {
  color: #3C414E;
}
aside .sidebar-recent-posts ul li a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
aside .siderbar-categories ul li {
  margin-bottom: 9px;
}
aside .siderbar-categories ul li a {
  font-size: 16px;
  color: #98A440;
}
aside .siderbar-categories ul li a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.slider-home {
  position: relative;
  width: 100%;
  height: calc(100vh);
  z-index: 200;
}
.slider-home .slick-arrow {
  position: absolute;
  display: block;
  top: 50%;
  width: 14px;
  height: 50px;
  margin-top: -25px;
  z-index: 300;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
}
.slider-home .slick-arrow.slick-prev {
  background: url(../img/arrow-left.png) no-repeat center center;
  left: 44px;
}
.slider-home .slick-arrow.slick-next {
  right: 44px;
  background: url(../img/arrow-right.png) no-repeat center center;
}
.slider-home .slick-arrow:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.slider-home .title-block {
  height: 100vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.slider-home .title-block .logo-slider {
  display: block;
  width: 155px;
  height: 145px;
  margin: 0 auto;
  background: url(../img/logo-slider.png) no-repeat center center;
}
.slider-home .title-block .big-title {
  font-family: 'Playfair Display';
  display: inline-block;
  color: #FFFFFF;
  font-size: 50px;
  background-color: rgba(32, 30, 30, 0.8);
  padding: 15px 32px;
  margin-bottom: 6px;
  letter-spacing: 0.018em;
}
.slider-home .title-block .small-title {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
  color: #FFFFFF;
  background-color: rgba(32, 30, 30, 0.8);
  padding: 15px 32px;
  letter-spacing: 0.24em;
}
@media (max-width: 768px) {
  .slider-home .title-block .small-title {
    width: 90%;
  }
}
.slider-home .title-block .button {
  display: inline-block;
  padding: 17px 39px;
  margin-top: 33px;
}
.slider-home .slick-dots {
  position: absolute;
  width: 100%;
  z-index: 500;
  height: 10px;
  text-align: center;
  bottom: 40px;
}
.slider-home .slick-dots li {
  display: inline-block;
  margin-right: 10px;
}
.slider-home .slick-dots li:last-child {
  margin: 0;
}
.slider-home .slick-dots li.slick-active button {
  background-color: #FFFFFF;
}
.slider-home .slick-dots li button {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #A62E24;
  font-size: 0;
  border: none;
  padding: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.slider-home .slick-list {
  height: 100%;
  width: 100%;
}
.slider-home .slick-list .slick-track {
  height: 100%;
  width: 100%;
}
.slider-home .slick-list .slick-track .item-slider {
  background-size: cover;
}
.section-about-us {
  padding: 104px 0 132px 0;
}
.section-about-us .itens-about-us {
  margin-top: 75px;
}
.section-about-us .itens-about-us article figure {
  display: block;
  width: 100%;
  height: 211px;
  background-size: cover;
}
.section-about-us .itens-about-us article .title {
  font-weight: 400;
  color: #98A440;
  margin: 33px 0 17px 3px;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.section-about-us .itens-about-us article .text,
.section-about-us .itens-about-us article p {
  font-size: 16px;
  font-weight: 300;
  color: #3C414E;
  margin: 0 0 0 3px;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.section-rezeption {
  padding: 96px 0 101px 0;
}
.section-rezeption article {
  margin-top: 50px;
}
.section-rezeption article .title {
  color: #A62E24;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-size: 18px;
  margin-bottom: 22px;
}
.section-rezeption article .text,
.section-rezeption article p {
  color: #58585A;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 21px;
  margin-bottom: 25px;
}
.section-rezeption article .link,
.section-rezeption article a {
  color: #98A440;
  font-size: 16px;
  letter-spacing: 0.01em;
  font-weight: 300;
}
.section-rezeption article .link:hover,
.section-rezeption article a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.section-rezeption .last-article-rezeption {
  margin-top: 25px;
}
@media (max-width: 425px) {
  .section-rezeption .last-article-rezeption {
    margin-top: 50px;
  }
}
.section-payment {
  background: url(../img/bg-section-payment.png) no-repeat center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 73px 0 69px 0;
  min-height: 600px;
}
@media (max-width: 370px) {
  .section-payment {
    min-height: 400px;
  }
}
.section-payment .box-text {
  margin-top: 66px;
}
.section-payment .box-text .text {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.01em;
  margin-bottom: 22px;
}
.section-payment .box-text .link {
  color: #98A440;
  font-size: 16px;
  letter-spacing: 0.01em;
  font-weight: 300;
}
.section-payment .box-text .link:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.section-booking {
  background-color: #F1F1EC;
  padding: 66px 0 73px 0;
}
.section-booking .booking-box {
  padding: 50px 0 0 0;
}
.section-booking .title-box {
  font-family: 'Playfair Display';
  color: #A62E24;
  font-size: 22px;
  letter-spacing: 0.06em;
  margin-bottom: 29px;
}
.section-booking .text {
  font-size: 16px;
  color: #58585A;
}
.section-booking .address {
  font-size: 22px;
  color: #58585A;
}
.section-booking .address a {
  color: #58585A;
}
.section-booking .address a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.section-booking .button {
  padding: 16px 27px;
}
.section-booking textarea {
  height: 128px;
}
.section-news {
  padding: 89px 0 0 0;
}
.section-news .box-news .title-box {
  font-family: 'Playfair Display';
  color: #A62E24;
  font-size: 22px;
  letter-spacing: 0.06em;
  margin: 75px 0 44px 0;
}
.section-news .box-news .list-news {
  list-style-type: none;
  margin-top: 100px !important;
}
.section-news .box-news .list-news li {
  font-family: 'Playfair Display';
  color: #58585A;
  font-size: 20px;
  margin-bottom: 13px;
}
.section-news .box-news .list-news li .date {
  margin-right: 5px;
}
.section-news .box-news .list-news li .link {
  font-family: 'Playfair Display';
  color: #58585A;
  font-size: 20px;
}
.section-news .box-news .list-news li .link:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.section-news .box-news .btn-all-news {
  width: 150px;
  padding: 10px;
  margin-top: 30px;
}
.section-news .box-events .title-box {
  font-family: 'Playfair Display';
  color: #FFFFFF;
  font-size: 22px;
  letter-spacing: 0.06em;
  margin: 143px 0 0 37px;
}
.section-news .box-events .list-events {
  margin: 45px 0 0 36px;
}
.section-news .box-events .list-events li {
  position: relative;
  margin: 0 0 37px 0;
}
.section-news .box-events .list-events li:before {
  content: "";
  position: absolute;
  width: 46px;
  border-bottom: 1px solid #FFFFFF;
  bottom: -19px;
}
.section-news .box-events .list-events li .title-event {
  display: block;
  font-family: 'Playfair Display';
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 4px;
}
.section-news .box-events .list-events li .date-event {
  font-family: 'Playfair Display';
  font-size: 13px;
  color: #FFFFFF;
}
.section-seo {
  padding: 148px 0 130px 0;
}
.section-seo .text {
  font-size: 16px;
  color: #58585A;
  margin-top: 69px;
}
@media (min-width: 426px) and (max-width: 768px) {
  .itens-about-us article {
    margin-bottom: 55px;
  }
  .booking-box .row {
    margin: 0 !important;
  }
  .section-booking form .row {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .section-news {
    background: none;
  }
  .section-news .box-events {
    background: url(../img/bg-events.png) no-repeat right top;
    background-size: cover !important;
  }
  .section-news .box-events .title-box {
    padding: 25px 0 40px 14px;
    margin: 0;
  }
  .section-news .box-events ul {
    margin: 0 !important;
  }
  .section-seo {
    padding-left: 25px;
    padding-right: 25px;
  }
  .section-about-us .itens-about-us article figure {
    height: 350px;
    background-position: center;
  }
}
.footer-main {
  background-color: #FBFBF3;
  padding: 50px 0 51px 0;
  overflow-x: hidden;
}
.footer-main .logo-footer {
  display: block;
  background: url(../img/logo-footer.png) no-repeat center center;
  width: 139px;
  height: 80px;
}
.footer-main .text {
  margin: 28px 0 0 0;
  letter-spacing: -0.033em;
  line-height: 22px;
}
.footer-main .title-footer {
  font-size: 16px;
  color: #A62E24;
  letter-spacing: 0.23em;
  text-transform: uppercase;
  margin-bottom: 22px;
}
.footer-main .link {
  color: #98A440;
}
.footer-main .link:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.footer-copy {
  padding: 7px 0;
  background-color: #58585A;
  color: #FFFFFF;
}
.footer-copy p {
  font-size: 13px;
  margin: 0;
  font-weight: 300;
}
.footer-copy a {
  color: #FFFFFF;
}
.footer-copy .facebook {
  display: block;
  width: 15px;
  height: 15px;
  background: url(../img/icon-facebook.png) no-repeat center center;
  margin-left: 50px;
}
@media (max-width: 768px) {
  footer {
    text-align: center;
  }
  footer .logo-footer {
    float: none;
    margin: 0 auto;
  }
  .footer-copy p {
    width: 100%;
    float: none;
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }
  .footer-copy .facebook {
    clear: both;
    float: none;
  }
  .footer-copy .facebook {
    float: none !important;
    display: block;
    clear: both;
    margin: 15px auto !important;
  }
}
.section-product-page {
  padding: 95px 0 145px 0;
}
.section-product-page .product-itens {
  margin-top: 75px;
}
.section-product-page .product-itens article figure {
  display: block;
  width: 100%;
  height: 173px;
  background-size: cover;
}
.section-product-page .product-itens article .title {
  font-weight: 400;
  color: #A62E24;
  margin: 27px 0 17px 3px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.24em;
}
.section-product-page .product-itens article .text {
  min-height: 120px;
  font-size: 16px;
  font-weight: 300;
  color: #3C414E;
  margin: 0 0 0 3px;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.section-product-page .product-itens article .link {
  display: block;
  font-size: 16px;
  color: #98A440;
  margin-top: 22px;
  margin-bottom: 40px;
}
.section-product-page .box-general-information {
  padding: 124px 0 0 0;
}
.section-product-page .box-general-information .title-general-information {
  font-family: 'Playfair Display';
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
}
.section-product-page .box-general-information .subtitle-general-information,
.section-product-page .box-general-information strong {
  font-family: 'Playfair Display';
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #A62E24;
  margin: 43px 0 16px 0;
}
.section-product-page .box-general-information .text {
  color: #3C414E;
  font-size: 16px;
}
.section-product-page .box-general-information .title-dog-friendly {
  color: #3C414E;
  font-size: 16px;
  font-weight: 700;
  margin-top: 38px;
}
@media (max-width: 768px) {
  .product-itens article {
    margin-bottom: 50px;
  }
  .product-itens article:last-child {
    margin-bottom: 0;
  }
}
.section-product-single {
  padding: 105px;
}
.section-product-single .title-itens {
  font-family: 'Playfair Display';
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #A62E24;
  margin: 0 0 16px 0;
}
.section-product-single .title-itens.equipment {
  margin: 48px 0 12px 0;
}
.section-product-single .title-itens.localization {
  margin: 35px 0 11px 0;
}
.section-product-single .title-itens.additional {
  margin: 35px 0 11px 0;
}
.section-product-single hr {
  border-color: #98A440;
  margin: 30px 0;
}
.section-product-single .back {
  display: inline-block;
  color: #3C414E;
  font-size: 16px;
  cursor: pointer;
}
.section-product-single .back:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.section-product-single .list-itens,
.section-product-single ul,
.section-product-single p {
  color: #3C414E;
  font-size: 16px;
}
.section-product-single .list-itens li,
.section-product-single ul li,
.section-product-single p li {
  margin-bottom: 7px;
}
.section-product-single .title-dog-friendly {
  color: #3C414E;
  font-size: 16px;
  font-weight: 700;
  margin-top: 38px;
}
.section-product-single .figure {
  margin-bottom: 25px;
}
@media (max-width: 1024px) {
  .section-product-single {
    padding: 0;
  }
}
.slider-services {
  position: relative;
  width: 100%;
  height: 410px;
  z-index: 200;
}
@media (max-width: 425px) {
  .slider-services {
    height: 250px;
  }
}
.slider-services .slick-arrow {
  position: absolute;
  display: block;
  top: 50%;
  width: 14px;
  height: 50px;
  margin-top: -25px;
  z-index: 300;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
}
.slider-services .slick-arrow.slick-prev {
  background: url(../img/arrow-left.png) no-repeat center center;
  left: 44px;
}
.slider-services .slick-arrow.slick-next {
  right: 44px;
  background: url(../img/arrow-right.png) no-repeat center center;
}
.slider-services .slick-arrow:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.slider-services .slick-dots {
  position: absolute;
  width: 100%;
  z-index: 500;
  height: 10px;
  text-align: center;
  bottom: 40px;
}
.slider-services .slick-dots li {
  display: inline-block;
  margin-right: 10px;
}
.slider-services .slick-dots li:last-child {
  margin: 0;
}
.slider-services .slick-dots li.slick-active button {
  background-color: #FFFFFF;
}
.slider-services .slick-dots li button {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #A62E24;
  font-size: 0;
  border: none;
  padding: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.slider-services .slick-list {
  height: 100%;
}
.slider-services .slick-list .slick-track {
  height: 100%;
}
@media (max-width: 425px) {
  .slider-services .slick-list .slick-track {
    height: 250px;
  }
}
.slider-services .slick-list .slick-track .item-slider {
  background-position: center center;
  background-size: cover;
}
.section-services {
  padding: 95px 0 85px 0;
}
.section-services .title-services {
  font-family: 'Playfair Display';
  color: #3C414E;
  font-size: 16px;
  font-weight: 700;
  margin: 71px 0 23px 0;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.section-services .subtitle-services {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  color: #A62E24;
  margin: 37px 0 16px 0;
}
.section-services .text {
  font-size: 16px;
  color: #3C414E;
  letter-spacing: 0.027em;
}
.section-services hr {
  border-color: #98A440;
}
.section-buffet {
  padding: 98px 0 104px 0;
  background: url(../img/bg-buffet.png) no-repeat center center;
  background-size: cover;
}
.section-buffet .title-buffet {
  font-family: 'Playfair Display';
  color: #3C414E;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 23px 0;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
.section-buffet hr {
  border-color: #98A440;
  margin-bottom: 33px;
}
.section-buffet .text {
  font-size: 16px;
  color: #3C414E;
  letter-spacing: 0.03em;
  line-height: 25px;
}
.section-buffet .list-buffet {
  font-size: 16px;
  color: #A62E24;
  margin-top: 0px;
}
.section-buffet .list-buffet li {
  margin-bottom: 7px;
}
.section-about-us-page {
  padding: 104px 0 132px 0;
}
.section-about-us-page .box-info {
  display: block;
  clear: both;
  margin: 75px 0 0 0;
}
.section-about-us-page .text {
  font-size: 16px;
  color: #3C414E;
  line-height: 24px;
  margin: -5px 0 27px 0;
}
@media (max-width: 768px) {
  img {
    margin-bottom: 25px;
  }
}
.news-page {
  padding: 94px 0 74px 0;
}
.news-page .list-posts {
  margin-top: 80px;
  list-style-type: none;
}
.news-page .list-posts li {
  /*margin-bottom: 93px;*/
  min-height: 380px;
}
.news-page .list-posts li figure {
  width: 100%;
  height: 250px;
  background-size: cover !important;
}
.news-page .list-posts li .date {
  font-size: 16px;
  color: #3C414E;
  margin: 14px 0 0 0;
  display: block;
}
.news-page .list-posts li .title-post {
  font-size: 18px;
  color: #A62E24;
  text-transform: uppercase;
  margin: 13px 0 0 0;
  letter-spacing: 0.23em;
  line-height: 22px;
}
.news-post {
  padding: 41px 0 127px 0;
}
.news-post header figure {
  margin: 0 0 44px 0;
}
.news-post header .title-post {
  font-family: 'Playfair Display';
  font-size: 44px;
  color: #A62E24;
  font-weight: 700;
  letter-spacing: -0.003em;
  margin-bottom: 13px;
}
.news-post .bread-crumb {
  margin-bottom: 30px;
}
.news-post .bread-crumb li {
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  font-style: italic;
  color: #58585A;
  font-size: 15px;
}
.news-post .bread-crumb li a {
  color: #98A440;
}
.news-post .bread-crumb li a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.news-post .categories {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
}
.news-post .categories p {
  display: inline-block;
  color: #58585A;
}
.news-post .categories a {
  color: #98A440;
  display: inline-block;
}
.news-post .categories a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.news-post .post-info {
  border-top: 1px solid rgba(88, 88, 90, 0.2);
  border-bottom: 1px solid rgba(88, 88, 90, 0.2);
  padding: 13px 15px;
  margin-bottom: 38px;
}
.news-post .post-info .date {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #58585A;
  letter-spacing: 0.04em;
}
.news-post .post-info .by {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #58585A;
  margin: 0 0 0 26px;
  letter-spacing: 0.03em;
}
.news-post .post-info .by a {
  color: #98A440;
}
.news-post .post-info .by a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.news-post .post-info .comments {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.03em;
  color: #98A440;
  margin: 0 0 0 21px;
}
.news-post .post-info .comments:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.news-post .post-info .share p {
  font-size: 16px;
  margin: 0 10px 0 0;
  display: inline-block;
  color: #3C414E;
}
.news-post .post-info .share a {
  display: inline-block;
}
.news-post .post-info .share a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.news-post .post-info .share a.facebook {
  width: 12px;
  height: 12px;
  background: url(../img/icon-share-facebook.png) no-repeat center center;
  margin-right: 7px;
}
.news-post .post-info .share a.twitter {
  width: 15px;
  height: 12px;
  background: url(../img/icon-share-twitter.png) no-repeat center center;
  margin-right: 7px;
}
.news-post .post-info .share a.gplus {
  width: 12px;
  height: 12px;
  background: url(../img/icon-share-gplus.png) no-repeat center center;
  margin-right: 7px;
}
.news-post .post-info .share a.pinterest {
  width: 12px;
  height: 12px;
  background: url(../img/icon-share-pinterest.png) no-repeat center center;
  margin-right: 7px;
}
.news-post .post-info .share a.linkedin {
  width: 13px;
  height: 12px;
  background: url(../img/icon-share-linkedin.png) no-repeat center center;
  margin-right: 7px;
}
.news-post .post-info .share a.xing {
  width: 11px;
  height: 12px;
  background: url(../img/icon-share-xing.png) no-repeat center center;
}
.news-post .post-content p {
  font-size: 16px;
  color: #58585A;
  line-height: 24px;
  margin-bottom: 40px;
}
.news-post .post-content blockquote {
  width: 100%;
  box-sizing: border-box;
  text-transform: uppercase;
  background: #F6FBF8;
  border: none;
  margin: 44px 0;
  padding: 28px;
}
.news-post .post-content .gallery-image li {
  display: inline-block;
  margin: 0 15px;
}
.news-post .post-content img {
  margin-bottom: 27px;
}
@media (max-width: 768px) {
  .news-post header .title-post {
    font-size: 22px;
  }
  .bread-crumb li:last-child {
    display: none;
  }
  .post-info .by {
    float: right !important;
  }
  .post-info .comments {
    float: left;
    clear: left;
    margin: 17px 0 0 0!important;
  }
  .post-info .share {
    margin-top: 15px;
  }
  .gallery-image li {
    display: inline-block;
    margin: 0 10px !important;
  }
}
.contact-page {
  padding: 95px 0 128px 0;
}
.contact-page .box-form {
  padding-top: 62px;
  /*
        form{
            input[type="text"],
            input[type="email"],
            select{
                color: black;
                padding: 24px 15px;
            }

            select{
                border-radius: 0;
                height: 48px;
                //.appearence();
            }

            textarea{
                height: 263px;
            }

            input[type="submit"], button{
                padding: 15px 70px;
            }
        }
        */
}
.contact-page .box-form .title-box {
  font-size: 18px;
  color: #A62E24;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  margin-bottom: 36px;
}
.contact-page .box-address {
  margin-bottom: 62px;
}
.contact-page .box-address .text-address {
  font-size: 16px;
  color: #3C414E;
}
.contact-page .box-address .text-address a {
  color: #98A440;
}
.contact-page .box-address .text-address a:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}
.contact-page form input[type="text"],
.contact-page form input[type="email"],
.contact-page form textarea,
.contact-page form select {
  background-color: #F0F0F0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.contact-page form select {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}
.contact-page form textarea.form-control {
  min-height: 250px;
}
.contact-page form button.button {
  padding: 24px 15px;
}
.contact-page .form-control {
  min-height: 50px;
}
.contact-page .box-info {
  padding: 106px 0 0 0;
}
.contact-page .box-info .title-info {
  font-size: 18px;
  color: #98A440;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  margin-bottom: 32px;
}
.contact-page .box-info .list-info li {
  color: #3C414E;
  font-size: 16px;
  margin-bottom: 2px;
}
.error-404 {
  padding: 191px 0 162px 0;
}
.error-404 small {
  display: block;
  font-size: 18px;
  color: #3C414E;
  font-weight: bold;
  letter-spacing: 0.3em;
}
.error-404 big {
  font-family: 'Playfair Display';
  display: block;
  font-size: 50px;
  color: #A62E24;
  padding-bottom: 87px;
}
.error-404 a {
  display: block;
  color: #98A440;
  font-size: 16px;
  margin-bottom: 16px;
}
.standard-page {
  padding: 191px 0 162px 0;
}
.standard-page .header-section {
  margin-bottom: 126px;
}
.standard-page h2 {
  font-size: 18px;
  color: #A62E24;
  margin-bottom: 40px;
}
.standard-page p {
  display: block;
  font-size: 16px;
  color: #3C414E;
  line-height: 23px;
}
.standard-page img {
  margin: 30px 0 70px 0;
}
@media (max-width: 575px) {
  .slider-home .slick-arrow {
    display: none !important;
  }
  .slider-home .title-block .big-title {
    padding: 5px 20px;
    font-size: 31px;
    margin-top: 64px;
    width: 90%;
  }
  .slider-home .title-block .button {
    margin-top: 15px;
  }
  .slider-home .slick-dots {
    bottom: 20px;
  }
}
@media (max-width: 767px) {
}
@media (max-width: 991px) {
}
@media (max-width: 1199px) {
}
